import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog5/infografika.png';
import unsupportiveEnviroment from '../../../images/educational-corner/blog5/Nepodrzavajuca-okolina.png';
import supportiveEnviroment from '../../../images/educational-corner/blog5/Poticajna-okolina.png';
import rutineAndGames from '../../../images/educational-corner/blog5/Rutine-i-igre.png';

const EnviromentalImpactOnSpeech = () => {
  return (
    <PageNew
      title="KOKOLINGO - Utjecaj okoline na jezično-govorni razvoj djeteta"
      description="Djeca su jezikom okružena te na njega reagiraju. Ako nema poticajnu
      okolinu, njegov jezično-govorni, ali i cjelokupni razvoj odstupat će od
      očekivanog."
      headline="Utjecaj okoline na jezično-govorni razvoj djeteta"
    >
      <Text as="p">
        Djeca su jezikom okružena i prije samog rođenja te na njega reagiraju.
        Nakon rođenja ta “uronjenost” u jezik postaje sve veća. Majke i očevi
        djeci pjevaju, braća i sestre igraju se s njima, a djedovi i bake vode
        ih na tržnicu. Sve te osobe i sve situacije u kojima se djeca nalaze
        čine njihovu neposrednu okolinu te doprinose njihovu jezično-govornom
        razvoju. Kako bi dijete napredovalo, mora imati određene karakteristike
        - urednu intelektualnu sposobnost, uredan sluh i motoriku, govorne
        organe bez odstupanja itd. Međutim,
        <b>
          {' '}
          ako dijete ima sve te karakteristike, ali nema poticajnu okolinu,
          njegov jezično-govorni, ali i cjelokupni razvoj odstupat će od
          očekivanog.
        </b>
      </Text>
      <Text as="h2">Poticajna okolina</Text>
      <Text as="p">
        Tijekom prvih devet mjeseci života djeca šalju poruke okolini, ali ne s
        određenom namjerom. Osobe iz okoline reagirat će na takve poruke i
        dekodirati ih prema djetetovu ponašanju ili raspoloženju. Primjerice,
        majka će po djetetovu plaču zaključiti da je ono gladno ili da ga treba
        presvući. Potkraj prve godine djeca će postupno početi komunicirati u
        određene svrhe, primjerice da dobiju igračku ili da ih se nosi.
        <b>
          {' '}
          Razdoblje od rođenja do prve godine važno je jer dijete postupno
          otkriva da raznim oblicima komunikacije može utjecati na ponašanje
          ljudi iz svoje okoline. Zbog toga je bitno da okolina reagira na svaku
          njegovu poruku, bila ona poslana s određenom namjerom ili bez nje.{' '}
        </b>
        Tako ostvarujemo komunikaciju s djetetom i potičemo ga da ulazi u
        interakcije. Naravno, svoj ćemo govor, kada se obraćamo djetetu,
        prilagoditi njegovim trenutačnim sposobnostima. Tijekom prve godine on
        će biti promjenjive intonacije, jasniji, više visine glasa, sporiji i s
        duljim stankama. Koristit ćemo se kraćim i jednostavnijim riječima,
        usmjerenim na ovdje i sada. Rečenice će biti kraće, a postavljat ćemo
        više pitanja. Često ćemo ponavljati ili parafrazirati dječje iskaze.
        Roditelji, ali i ostale osobe iz okoline, spontano se prilagode ovakvom
        načinu komunikacije. S vremenom, kako dijete sve više raste i razvija
        se, tako se i okolina, spontano, prilagođava njegovim novim
        sposobnostima. U razvoju jezičnih i govornih sposobnosti veliku ulogu
        imaju i dnevne rutine i igre.
      </Text>
      <BlogImage
        imgSrc={supportiveEnviroment}
        imgAlt="Poticajna okolina kod djece"
        imgTitle="Poticajna okolina"
      />
      <Text as="h2">Rutine i igre</Text>
      <Text as="p">
        Presvlačenje, hranjenje i priprema za spavanje neke su od svakodnevnih
        rutina u kojima sudjeluju roditelji i dijete. One su izvor učenja jer
        roditelji, sasvim spontano, opisuju sve ono što u tom trenutku rade oni
        ili dijete. Primjerice, ako dijete hrani plišanu igračku zeca, roditelj
        će tu radnju popratiti s “Hraniš zeku!” ili će taj iskaz proširiti sa
        “Što jede zeko? Mrkvu ili čokoladu?”. Tako dijete obogaćuje svoj rječnik
        i usvaja nova jezična znanja.
        <b>
          {' '}
          Djeca koja čuju veću količinu jezika - priča, opisa, razgovora, imat
          će i razvijenije jezične vještine.{' '}
        </b>
      </Text>
      <Text as="p">
        To se posebice odnosi na situacije kada se igramo s djecom. Igre koje
        uključuju više osoba i preuzimanje raznih uloga, primjerice vitez i zmaj
        ili policajac i lopov, pridonose usvajanju novih znanja jer uključuju
        složenije jezične strukture i oblike. Važne su i igre u kojima djeca
        imaju “glavnu riječ”. Takvim igrama uče mnogo više novih riječi nego
        kada slijede interese odraslih. Ako djeca vode igru, moraju uložiti
        mnogo više truda u svoj govor, ali i u slušanje drugih da bi ih naveli
        na suradnju. Naravno, poželjno je da roditelji, ili netko drugi iz
        djetetove okoline, prihvati taj oblik igre jer njome dijete ne jača samo
        jezične nego i socioemocionalne vještine.
      </Text>
      <BlogImage
        imgSrc={rutineAndGames}
        imgAlt="Rutine i igre kod djece"
        imgTitle="Rutine i igre"
      />
      <Text as="h2">Nepodržavajuća okolina</Text>
      <Text as="p">
        Nepodržavajuća okolina može imati negativan utjecaj na rast i razvoj
        djeteta.
        <b>
          {' '}
          Ovakva okolina ne daje dovoljno podrške djetetu tijekom svakodnevnih
          rutina i interakcija.{' '}
        </b>
        Konkretno, u jezično-govornom smislu, to znači da osobe iz djetetove
        neposredne okoline ne sudjeluju u igrama s djetetom, ne pričaju mu
        priče, ne opisuju ili ne proširuju djetetove iskaze, često ga
        ispravljaju, ne odgovaraju na djetetova pitanja itd. Sa sve većim
        razvojem tehnologije, to se očituje i u učestalom, samostalnom
        provođenju vremena pred digitalnim sadržajima. U takvim situacijama
        dijete ne jača svoja jezična znanja jer nema uključivanja osoba iz
        okoline koje bi nadopunile tu aktivnost jezikom, odnosno govorom. Ako
        dijete ima uredan sluh, govor i intelektualne sposobnosti, a
        nepodržavajuću okolinu, to može imati negativne posljedice na
        jezično-govorni razvoj. Dijete će kasnije progovoriti, imat će
        siromašniji rječnik, koristit će se jednostavnijim i agramatičnim
        rečenicama, imat će problema u razumijevanju itd. Naravno, ako dijete
        ima teškoća u razvoju kognitivnih ili motoričkih sposobnosti, to će
        dodatno doprinijeti kašnjenju u jezično-govornom razvoju.
      </Text>
      <BlogImage
        imgSrc={unsupportiveEnviroment}
        imgAlt="Nepodrzavajuca okolina kod djece"
        imgTitle="Nepodrzavajuca okolina"
      />
      <Text as="p">
        Osim djetetovih urođenih sposobnosti, veliku ulogu u njegovu razvoju ima
        i okolina. Da bi se izbjegla kašnjenja u jezično-govornom razvoju,
        djetetova neposredna okolina mora biti poticajna. Roditelji i druge
        osobe iz okoline trebaju učestalo komunicirati s djecom i govor usmjeren
        djetetu prilagođavati njegovim trenutačnim sposobnostima. Sve ono što
        radimo spontano trebamo upotrebljavati svakodnevno - razgovarati o
        trenutačnim aktivnostima, imenovati, opisivati, prepričavati, zajedno se
        igrati, slijediti djetetove interese, uključiti dijete u aktivnosti
        poput kuhanja itd. Da bismo zadobili djetetovu pažnju, trebamo biti
        veseli i zaigrani. Uz rutine i igre dijete će najbolje razvijati svoja
        jezično-govorna znanja, a tako će razvijati i socioemocionalne vještine
        potrebne za svakodnevni život.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Andrešić, D. i Benc Štuka, N., ur. (2009).
            <i>
              Najčešći poremećaji jezično-govorne komunikacije djece predškolske
              dobi - Priručnik za roditelje, odgojitelje, pedijatre i sve koji
              prate razvoj djece
            </i>
            . Zagreb: Hrvatsko logopedsko društvo.
          </li>
          <li>
            Mišković, M. (2016).
            <i>
              Uloga roditelja u jezično-govornom razvoju djeteta predškolske
              dobi
            </i>
            . Diplomski rad. Zagreb: Sveučilište u Zagrebu,
            Edukacijsko-rehabilitacijski fakultet.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default EnviromentalImpactOnSpeech;
